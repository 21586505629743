import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import Link from 'components/ui/link/';
import StartUsing from 'components/ui/startUsing';
import { useFeatureData } from 'data/features';
import WorksWith from 'components/index/worksWith';
import TrustedBy from 'components/ui/trustedBy';
import FeaturesSlider from 'components/index/featuresSlider';
import SignUpForm from 'components/ui/signupForm';
import badge from 'img/index/GDPRbadge.svg';
import badge2 from 'img/index/CCPAbadge.svg';
import * as Styled from './styled';

const Features = () => {
  const data = useFeatureData();
  return (
    <>
      <Styled.FeaturesContainer className="container">
        <Styled.Header>
          <h1 className="line">
            An amazing blend of <span>features</span> to help you grow
          </h1>
          <p className="page-desc">Because everything has to work together just right</p>
          <SignUpForm />
        </Styled.Header>

        <FeaturesSlider
          sectionTitle={
            <h2 className="line">
              The essentials for <br /> <span>successful UX</span>
            </h2>
          }
          sectionDesc="Use all the necessary tools LiveSession provides you with to enhance your website’s UI. Grow your user base or sell more thanks to well-thought design improvements with a little help of:"
          cards={data.yellow.cards}
          images={data.yellow.images}
        />
        <TrustedBy hero />
        <FeaturesSlider
          sectionTitle={
            <h2 className="line">
              Focus on things that <br /> are <span>bring real value</span>
            </h2>
          }
          sectionDesc="Shorten the time needed to do your research. Never miss an important session again."
          cards={data.purple.cards}
          images={data.purple.images}
          reversed
          color="#8864FE"
          className="secondary"
        />
        <FeaturesSlider
          sectionTitle={
            <h2 className="line">
              <span>Find regularities</span> <br /> in your users&apos; behavior
            </h2>
          }
          sectionDesc="Gather insights into your users’ behavior. Rethink your website’s flow to recreate conditions in which your visitors convert. Improve your customer support."
          cards={data.blue.cards}
          color="#7DFFEE"
          images={data.blue.images}
          className="secondary"
        />
        <Styled.PersonalDataSection>
          <div className="content">
            <h2>Personal data protection and privacy are rooted in our values</h2>
            <p>
              We allow you to anonymize text and images containing sensitive data. Take care of your
              customers&apos; security. LiveSession is GDPR and CCPA compliant.
            </p>
            <Link withArrow href="/help/how-to-anonymize-sensitive-page-content/">
              See how to hide sensitive data
            </Link>
            <Styled.ImageContainer>
              <Link href="/help/gdpr/">
                <img src={badge} alt="GDPR badge" title="GDPR Compiliant" className="img-fluid" />
              </Link>
              <Link href="/help/ccpa/">
                <img src={badge2} alt="CCPA badge" title="CCPA Compiliant" className="img-fluid" />
              </Link>
            </Styled.ImageContainer>
          </div>
          <Img
            fluid={data.personalData.mainImage}
            objectFit="contain"
            objectPosition="center center"
            className="main-image"
          />
        </Styled.PersonalDataSection>
        <Styled.MainFeaturesSection>
          <h2 className="line">
            Find <span>the most valuable sessions</span> in a blink of an eye
          </h2>
          <p className="subtitle">Focus on finding solutions, not searching for problems</p>
          <Styled.CardContainer>
            {data.featureCards.map((item) => (
              <div key={item.title}>
                <Img fluid={item.image} />
                <h3>{item.title}</h3>
                {item.desc}
              </div>
            ))}
          </Styled.CardContainer>
        </Styled.MainFeaturesSection>
      </Styled.FeaturesContainer>
      <WorksWith />
      <TrustedBy customText="Trusted by" style={{ paddingBottom: 20 }} />
      <StartUsing />
    </>
  );
};

export default Features;
